import * as React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, GridItem } from '@components/grid'
import { Header, Masthead, TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { palette } from '@theme'
import { lazy } from '@loadable/component'


const LazyWork = lazy(() => import('@components/threedee/Work'))


const WorkPage = ({data: { 
  sanityPage: {
    gridItems,
    reflectionImage,
    mastheadImage,
    mobileMastheadImage,
    modelPosition,
    modelPositionMobile,
  }
}}: PageProps) => {
  return (
    <>
      <Header />
      {reflectionImage && mastheadImage && (
        <Masthead {...{ reflectionImage, mastheadImage, mobileMastheadImage }} short >
          <LazyWork {...{ modelPosition, modelPositionMobile }}  />
        </Masthead>
      )}
      <main>
        <Grid>
          {gridItems.filter(p => !!p).map((item) => (
            <StyledGridItem key={item._key} {...item} square >
              {item.post && (
                <WorkDescription>
                  <h2 className="hoverTextColor" >{item.post.title}</h2>
                  <p>{item.post.description}</p>
                  <Year>{item.post.year}</Year>
                </WorkDescription>
              )}
            </StyledGridItem>
          ))}
        </Grid>
      </main>
      <TransitionMask />
    </>
  )
}


const StyledGridItem = styled(props => <GridItem {...props} />)`
  img, video {
    border-radius: 0px !important;  
  }
`


const WorkDescription = styled.div`
  p {
    color: ${palette.gray};
  }
  h2 {
    display: inline !important;
    box-decoration-break: clone;
    margin-top: 0.4em;
  }
`

const Year = styled.div`
  margin-top: 0.5em;
  text-align: right;
`




export const query = graphql`
  query WorkPageQuery {
    sanityPage(slug: {current: {eq: "work"}}) {
      ...pageFields
    }
  }
`



export default WorkPage